<template>
    <div>
        <v-row no-gutters v-if="!showCalendar">
            <v-col cols="12">
                <v-data-table
                    id="virtual-scroll-table"
                    :headers="headers"
                    :items="partialDeliveriesFiltered"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                    @click:row="openProcessBOM"
                    :item-class="itemRowBackground"
                    style="cursor: pointer"
                >
                    <template v-slot:top>
                        <div>
                            <v-row
                                no-gutter
                                class="d-flex justify-center mt-0 mx-0 pt-4 pb-0 pb-4"
                                style="background-color: #eeeeee"
                            >
                                <v-col cols="6  " class="d-flex">
                                    <h2 class="mx-4 my-n3">ITEMS</h2>
                                </v-col>

                                <v-spacer />
                                <div>
                                    <v-col cols="6" class="d-flex">
                                        <v-btn
                                            class="mx-2 my-n3"
                                            color="primary"
                                            @click="openInform = true"
                                        >
                                            <v-icon>mdi-file-excel</v-icon>
                                            INFORM
                                        </v-btn>
                                        <v-btn
                                            class="mx-2 my-n3"
                                            color="primary"
                                            @click="switchToCalendar"
                                        >
                                            CALENDAR
                                        </v-btn>
                                    </v-col>
                                </div>
                            </v-row>
                        </div>
                    </template>
                    <!--HEADER FILTER-->
                    <template v-slot:[`header.code`]="{ header }">
                        <v-text-field
                            class="py-2 mt-2"
                            :label="header.text"
                            v-model="search"
                            dense
                            prepend-icon="mdi-filter"
                        />
                    </template>
                    <!--ITEMS-->
                    <template v-slot:[`item.index`]="{ index }">
                        <p class="my-0">
                            {{ index + 1 }}
                        </p>
                    </template>
                    <template v-slot:[`item.createdBy`]="{ item }">
                        <p class="my-0">
                            {{ findUserName(item.createdBy) }}
                        </p>
                    </template>
                    <template v-slot:[`item.createdOn`]="{ item }">
                        <p class="my-0">
                            {{ getDate(item.createdOn) }}
                        </p>
                    </template>
                    <template v-slot:[`item.deliverDate`]="{ item }">
                        <p v-if="item.deliverDate" class="my-0">
                            {{ item.deliverDate.substring(0, 10) }}
                        </p>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="d-flex justify-center ">
                            <v-progress-circular
                                size="25"
                                color="primary"
                                v-if="loading && selectedItem.id == item.id"
                                indeterminate
                            />
                            <v-menu
                                rounded
                                offset-y
                                v-if="
                                    !(
                                        item.reasonsForClosing &&
                                        Object.keys(item.reasonsForClosing)
                                            .length > 0
                                    )
                                "
                            >
                                <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        depressed
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item
                                        @click.stop="openRejectPartials(item)"
                                    >
                                        <v-list-item-icon class="">
                                            <v-icon
                                                >mdi-cube-off-outline</v-icon
                                            >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Reject
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click.stop="openClosingDialog(item)"
                                    >
                                        <v-list-item-icon class="">
                                            <v-icon
                                                >mdi-close-box-outline</v-icon
                                            >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Close
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row no-gutters v-else>
            <v-col cols="12">
                <div>
                    <v-progress-linear v-if="loading" indeterminate />
                    <v-toolbar
                        flat
                        :loading="loading"
                        color="#eeeeee"
                        style="border: #e0e0e0 1px solid;"
                    >
                        <v-btn
                            outlined
                            class="mr-4"
                            color="grey darken-2"
                            @click="setToday"
                        >
                            Today
                        </v-btn>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="prev"
                        >
                            <v-icon small>
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>
                        <v-btn
                            fab
                            text
                            small
                            color="grey darken-2"
                            @click="next"
                        >
                            <v-icon small>
                                mdi-chevron-right
                            </v-icon>
                        </v-btn>
                        <v-toolbar-title v-if="$refs.calendar">
                            {{ $refs.calendar.title }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="my-n3"
                            color="primary"
                            @click="showCalendar = false"
                        >
                            LIST
                        </v-btn>
                    </v-toolbar>
                </div>
                <div>
                    <v-calendar
                        :loading="loading"
                        ref="calendar"
                        v-model="focus"
                        color="primary"
                        :events="events"
                        type="month"
                        :event-height="100"
                        @click:event="showEvent"
                        @change="setEvents"
                    >
                        <template slot="dayMonth" slot-scope="{ day }">
                            <div class="day">dayHeader slot {{ day }}</div>
                        </template>
                        <template #event="{event}">
                            <div class="pa-1">
                                <p class="my-0 text-wrap">
                                    {{ event.name }} ({{
                                        event.partial.quantity
                                    }})
                                </p>
                                <p class="my-0">
                                    user:
                                    {{ findUserName(event.partial.createdBy) }}
                                </p>
                            </div>
                        </template>
                    </v-calendar>
                </div>
            </v-col>
        </v-row>
        <!--PROCESS BOM-->
        <v-dialog
            v-model="processBOM"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <ProcessBOM
                v-if="processBOM"
                :bom="selectedBOM"
                :process="processName"
                :partialDelivery="true"
                :requestData="selectedRequestData"
                @close="closeProcessBOM"
                @sendToDeliver="sendToDeliver"
            />
        </v-dialog>
        <!--Closing Partial Delivery-->
        <v-dialog
            v-model="openClosingPartialDelivery"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <ClosingPartialDelivery
                v-if="openClosingPartialDelivery"
                :bom="selectedBOM"
                :process="processName"
                @close="closeProcessBOM"
                @remove="remove"
            />
        </v-dialog>
        <!--Reject Partials-->
        <v-dialog
            max-width="600px"
            :retain-focus="false"
            persistent
            v-model="rejectPartialsForm"
        >
            <v-card :loading="rejectLoading" v-if="rejectPartialsForm">
                <v-card-title class="text-h5">Reject Partials</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            Write the reasons why you decide to reject the
                            partial requests
                        </v-col>
                    </v-row>
                    <v-form v-model="valid">
                        <v-row no-gutters class="mb-2 mt-n5">
                            <v-col cols="12">
                                <v-textarea
                                    v-model="rejectedNotes"
                                    rows="2"
                                    hide-details
                                    label="Notes"
                                    prepend-icon="mdi-text"
                                    required
                                    :rules="[rules.required]"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeRejectPartials">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="rejectPartials"
                        text
                        color="error"
                        :loading="rejectLoading"
                        :disabled="!valid"
                    >
                        Reject
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Close Partial-->
        <v-dialog
            max-width="600px"
            :retain-focus="false"
            persistent
            v-model="closePartialsForm"
        >
            <ClosePartial
                v-if="closePartialsForm"
                :selectedBOM="selectedBOM"
                @close="closeClosingDialog"
                @replace="replace"
            />
        </v-dialog>
        <!-- Partial Deliveries For Report -->
        <v-dialog
            v-model="openInform"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <PartialDeliveriesForReport
                v-if="openInform"
                :users="users"
                @close="closeInform"
            />
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import moment from 'moment'
import _ from 'lodash'
export default {
    name: 'PartialDeliveryRequests',
    components: {
        ProcessBOM: () => import('@/components/WorkOrders/ProcessBOM.vue'),
        ClosePartial: () => import('@/components/WorkOrders/ClosePartial.vue'),
        ClosingPartialDelivery: () =>
            import('@/components/WorkOrders/ClosingPartialDelivery.vue'),
        PartialDeliveriesForReport: () =>
            import('@/components/WorkOrders/PartialDeliveriesForReport.vue'),
    },
    data: () => ({
        openInform: false,
        selectedItem: {},
        openClosingPartialDelivery: false,
        closePartialsForm: false,
        showCalendar: false,
        focus: '',
        events: [],
        colors: [
            'blue',
            'indigo',
            'deep-purple',
            'cyan',
            'green',
            'orange',
            'grey darken-1',
        ],
        projects: [],
        processName: 'Bill Of Material',
        selectedBOM: {},
        processBOM: false,
        users: [],
        partialDeliveries: [],
        loading: false,
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CODE',
                value: 'code',
                align: 'center',
                sortable: false,
                width: 200,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'REQUEST DATE',
                value: 'createdOn',
                align: 'center',
                sortable: false,
                width: 150,
            },
            {
                text: 'DELIVER DATE',
                value: 'deliverDate',
                align: 'center',
                sortable: false,
                width: 150,
            },
            {
                text: 'QTY',
                value: 'quantity',
                align: 'center',
                sortable: false,
                width: 100,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        search: null,
        menu: false,
        rejectPartialsForm: false,
        valid: false,
        rejectedNotes: null,
        rules: {
            required: v => !!v || 'The value is required',
        },
        rejectLoading: false,
        selectedRequestData: {},
        originalBOM: {},
    }),
    provide() {
        return {
            removePartialDelivery: this.removePartialDelivery,
        }
    },
    async created() {
        try {
            const {
                data: { users },
            } = await API.getUsers()
            this.users = users
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    async mounted() {
        try {
            this.loading = true
            await this.getData()
            if (this.showCalendar) {
                this.$refs.calendar.checkChange()
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    computed: {
        partialDeliveriesFiltered() {
            let conditions = []
            if (this.search) {
                conditions.push(this.filterBOM)
            }
            conditions.push(this.filterByQty)
            if (conditions.length > 0) {
                return this.partialDeliveries.filter(bom => {
                    return conditions.every(condition => {
                        return condition(bom)
                    })
                })
            }
            return this.partialDeliveries
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        closeInform() {
            this.openInform = false
        },
        remove(partialDeliveryId) {
            try {
                this.partialDeliveries = this.partialDeliveries.filter(
                    item => item.id != partialDeliveryId
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        itemRowBackground(item) {
            return item.showAlert
                ? 'background-color: alertRow !important'
                : item.reasonsForClosing &&
                  Object.keys(item.reasonsForClosing).length > 0
                ? 'background-color: closingRow !important'
                : 'style-2'
        },
        replace(bom) {
            const index = this.partialDeliveries.findIndex(
                item => item.id == this.selectedBOM.id
            )
            if (index >= 0) {
                this.partialDeliveries.splice(index, 1, {
                    ...this.partialDeliveries[index],
                    ...bom,
                })
                this.partialDeliveries = [...this.partialDeliveries]
            }
        },
        getDate(timestamp) {
            return moment
                .unix(timestamp._seconds || timestamp.seconds)
                .format('YYYY-MM-DD')
        },
        setToday() {
            this.focus = ''
        },
        prev() {
            this.$refs.calendar.prev()
        },
        next() {
            this.$refs.calendar.next()
        },
        async showEvent({ event }) {
            try {
                await this.openProcessBOM(event.partial)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        rnd(a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },
        getEventColor(event) {
            return event.color
        },
        setEvents() {
            let events = []
            for (const partial of this.partialDeliveries) {
                events.push({
                    name: partial.code + ' ' + partial.description,
                    start: partial.deliverDate.slice(0, 10),
                    end: partial.deliverDate.slice(0, 10),
                    //color: this.colors[this.rnd(0, this.colors.length - 1)],
                    timed: true,
                    partial,
                })
            }
            this.events = events
        },
        switchToCalendar() {
            this.setEvents()
            this.showCalendar = true
        },
        filterBOM(bom) {
            return bom.code.toLowerCase().includes(this.search.toLowerCase())
        },
        removePartialDelivery(item) {
            try {
                const index = this.partialDeliveries.findIndex(
                    partial => partial.id == item.partialDeliveryId
                )
                if (index >= 0) {
                    this.partialDeliveries[index].quantity -= Number(
                        item.quantityToDeliver
                    )
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        sendToDeliver() {
            this.processBOM = false
        },
        closeProcessBOM() {
            this.processBOM = false
            this.openClosingPartialDelivery = false
            this.selectedBOM = {}
            this.selectedItem = {}
            this.selectedRequestData = {}
        },
        async openProcessBOM(item) {
            try {
                this.selectedRequestData = {
                    id: item.id,
                    createdOn: item.createdOn,
                    deliverDate: item.deliverDate,
                }
                this.loading = true
                this.selectedItem = item
                let bom = await API.findBOMByCode(item.bomCode, item.bomId)
                const index = bom.items.findIndex(i => i.code == item.code)
                if (index == -1) {
                    throw new Error('BOM item not found.')
                }
                bom.items[index].requests = item.quantity
                bom.items[index].partialDeliveryId = item.id
                bom.items = [bom.items[index]]
                if (
                    item.reasonsForClosing &&
                    Object.keys(item.reasonsForClosing).length > 0
                ) {
                    bom.items[0].reasonsForClosing = item.reasonsForClosing
                    this.selectedBOM = bom
                    this.openClosingPartialDelivery = true
                } else {
                    this.selectedBOM = bom
                    this.processBOM = true
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async getData() {
            try {
                this.loading = true
                this.partialDeliveries = await API.getBOMPartialDeliveries()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
        formatDate(seconds, type = '') {
            if (type == 'date') {
                return moment.unix(seconds).format('YYYY-MM-DD')
            } else {
                return moment.unix(seconds).format('YYYY-MM-DD HH:mm:ss')
            }
        },
        findUserName(userId) {
            if (this.users && this.users.length > 0) {
                const user = this.users.find(user => user.id == userId)
                if (user) {
                    return user.name
                } else {
                    return undefined
                }
            } else {
                return undefined
            }
        },
        filterByQty(bom) {
            return bom.quantity > 0
        },
        openRejectPartials(item) {
            this.selectedBOM = _.cloneDeep(item)
            this.rejectPartialsForm = true
        },
        closeRejectPartials() {
            this.selectedBOM = {}
            this.rejectPartialsForm = false
        },
        async rejectPartials() {
            try {
                this.rejectLoading = true
                await API.rejectBOMPartials({
                    id: this.selectedBOM.bomId,
                    itemCode: this.selectedBOM.code,
                    requestId: this.selectedBOM.id,
                    workOrderId: this.selectedBOM.workOrderId,
                    rejectedNotes: this.rejectedNotes,
                    showAlert: true,
                })
                this.rejectedNotes = null
                this.rejectPartialsForm = false
                const requestIndex = this.partialDeliveries.findIndex(
                    request => request.id == this.selectedBOM.id
                )
                if (requestIndex > -1) {
                    this.partialDeliveries.splice(requestIndex, 1)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.rejectLoading = false
            }
        },
        openClosingDialog(item) {
            this.selectedBOM = _.cloneDeep(item)
            this.closePartialsForm = true
        },
        closeClosingDialog() {
            this.selectedBOM = {}
            this.closePartialsForm = false
        },
    },
}
</script>

<style scoped>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
.v-data-table >>> thead {
    background-color: #eeeeee;
}
.v-input .v-label {
    font-size: 12px;
}
.close_partial {
    background-color: #273746 !important;
}
.v-calendar >>> .v-calendar-weekly__head {
    background: #eeeeee !important;
}
.v-calendar >>> .v-calendar-weekly__head-weekday {
    font-family: 'Roboto', sans-serif !important;
    font-weight: bold !important;
    font-size: 0.75rem !important;
}
</style>
